import {buildUrl} from "shared/helpers/utils/url-builder";
import type {PreviewMainProgramPageParams, ViewMainProgramPageParams, ViewMainProgramTemplatePageParams} from "./types";
import {appPagePaths} from "./urls";

export const getHomeUrl = () => appPagePaths.home;
export const getLogoutUrl = () => appPagePaths.logout;
export const getAuthorizationUrl = () => appPagePaths.authorization;
export const getMainProgramsUrl = () => appPagePaths.mainPrograms;
export const getMainProgramTemplatesUrl = () => appPagePaths.mainProgramTemplates;
export const getViewMainProgramUrl = (params: ViewMainProgramPageParams) => buildUrl(appPagePaths.viewMainProgram, params);
export const getViewMainProgramTemplateUrl = (params: ViewMainProgramTemplatePageParams) => buildUrl(appPagePaths.viewMainProgramTemplate, params);
export const getCreateMainProgramUrl = () => appPagePaths.createMainProgram;
export const getPreviewMainProgramUrl = (params: PreviewMainProgramPageParams) => buildUrl(appPagePaths.previewMainProgram, params);
export const getWorkProgramsElementaryUrl = () => appPagePaths.workProgramsElementary;
export const getWorkProgramsGeneralUrl = () => appPagePaths.workProgramsGeneral;
export const getWorkProgramsSecondaryUrl = () => appPagePaths.workProgramsSecondary;

