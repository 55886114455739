import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {checkConvertedDocx} from "services/api";
import type {ViewMainProgramPageParams} from "services/navigation";

export const useCheckDocx = () => {
  const {mainProgramId} = useParams<ViewMainProgramPageParams>();
  const [isDocxButtonVisible, setIsDocxButtonVisible] = useState(false);
  const timerRef = useRef<NodeJS.Timer | null>(null);

  const startTimer = () => {
    timerRef.current = setInterval(async () => {
      const res = await checkConvertedDocx(mainProgramId!, false);

      if (res === "DONE") {
        setIsDocxButtonVisible(true);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      }

      if (res === "ERROR") {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      }
    }, 30000);
  };

  const initCheck = async () => {
    const res = await checkConvertedDocx(mainProgramId!, false);

    if (res === "DONE") {
      setIsDocxButtonVisible(true);
    } else {
      startTimer();
    }
  };

  useEffect(() => {
    initCheck();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return {
    isDocxButtonVisible
  };
};