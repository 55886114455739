import {AppTableControls} from "common/TableControls/TableControls";
import {AppText} from "shared/UI/UIKit/Text";
import {MainProgramStatusEnum} from "services/api";
import type {MouseEvent} from "react";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {MainProgram} from "services/api";

export const getMainProgramsTableColumns = (
  removeMainProgram: (record: MainProgram) => void,
  editMainProgram: (record: MainProgram) => void
): AppTableColumn<MainProgram>[] => {
  return [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "educationLevel",
      title: "Уровень образования",
      dataIndex: ["educationLevel"],
    },
    {
      key: "name",
      title: "Название",
      dataIndex: ["name"],
      render: (value: any, record: MainProgram) => {
        return (
          <AppText>
            {
              record.name ? (
                <>{record.name}</>
              ) : (
                <>Образовательная программа {record.educationLevel}</>
              )
            }
          </AppText>
        );
      }
    },
    {
      key: "createTs",
      type: "datetime",
      title: "Дата создания",
      dataIndex: ["createTs"],
    },
    {
      key: "createdBy",
      title: "Автор",
      dataIndex: ["createdBy"],
    },
    {
      key: "status",
      title: "Статус",
      dataIndex: ["status"],
      render: (value?: MainProgramStatusEnum) => {
        const published = value === MainProgramStatusEnum.PUBLISHED;
        return (
          <AppText type={published ? "success" : "warning"}>
            {published ? "Опубликована" : "Не опубликована"}
          </AppText>
        );
      }
    },
    {
      key: "controls",
      title: "",
      dataIndex: [],
      maxWidth: "5rem",
      onCell: () => {
        return {
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
          },
        };
      },
      render: (value: any, record: MainProgram) => {
        const removeProgram = () => removeMainProgram(record);
        const editProgram = () => editMainProgram(record);

        return (
          <AppTableControls edit={editProgram} remove={removeProgram}/>
        );
      }
    }
  ];
};