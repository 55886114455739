import {useEffect, useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppForm, AppFormItem, formRules, useAppForm} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppButton} from "shared/UI/UIKit/Button";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {updateMainProgramAction} from "features/main-programs/redux/actions";
import type {MainProgram} from "services/api";

type EditMainProgramModalProps = {
  visible: boolean;
  hideModal: () => void;
  mainProgram: MainProgram | null;
}

export const EditMainProgramModal = (props: EditMainProgramModalProps) => {
  const [form] = useAppForm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.mainProgram) {
      form.setFieldsValue({
        name: props.mainProgram.name || `Образовательная программа ${props.mainProgram.educationLevel}`,
      });
    }
  }, [props.mainProgram]);

  const submit = () => {
    setLoading(true);
    form.validateFields().then(values => {
      const body: MainProgram = {
        ...props.mainProgram!,
        name: values.name,
      };
      dispatch(updateMainProgramAction(body)).unwrap().then(() => {
        setLoading(false);
        hide();
      }).catch(() => setLoading(false));
    }).catch((e) => {
      console.error(e);
      setLoading(false);
    });
  };

  const hide = () => {
    props.hideModal();
    form.resetFields();
  };

  const content = (
    <AppForm form={form}>
      <AppFormItem name="name" label="Название программы" rules={formRules.required}>
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
    </AppForm>
  );

  const footer = (
    <AppButton onClick={submit}>
      Сохранить
    </AppButton>
  );

  return (
    <AppModal destroyOnClose loading={loading}
              title="Редактирование программы"
              visible={props.visible} onCancel={hide}
              content={content} footer={footer}/>
  );
};