import {notification} from "antd";
import {mainProgramAppApi} from "services/api/api-instance";
import type {Dictionary, DictionaryListResponse, DictionaryListRequest} from "./types";

export const getDictionaryList = (payload: DictionaryListRequest): Promise<DictionaryListResponse> => {
  return mainProgramAppApi
    .get("/work_program_reference/", {
      params: payload,
      paramsSerializer: {indexes: null}
    })
    .then(({data, headers}) => ({
      data,
      total: Number(headers["totalelements"]) || 0
    }))
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};

export const getDictionaryByCode = (code: string): Promise<Dictionary | null> => {
  return mainProgramAppApi
    .get("/work_program_reference/", {
      params: {
        code,
      },
    })
    .then(res => {
      return res.data[0] ? res.data[0] : null;
    })
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};
