import {useEffect, useState} from "react";
import {DocumentEditor} from "common/BlockEditors";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgram, selectMainProgramBlock} from "features/main-program/redux/selectors";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {SaveButton} from "features/main-program/components/MainProgramBlockViewer/StickyButton/StickyButton";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {updateMainProgramBlockAction} from "features/main-program/redux/actions";
import {PROGRAM_TEMPLATE_BLOCK_TYPE} from "services/api";
import {BlockTableAdditionalEducationEditor} from "common/BlockEditors/BlockTableAdditionalEducationEditor";
import {usePayloadedModal} from "shared/UI/UIKit/Modal";
import {
  WidgetBlockSettingsModal
} from "features/main-program/components/MainProgramBlockViewer/WidgetBlockSettingsModal";
import type {MouseEvent} from "react";
import {WidgetValueModal} from "./WidgetValueModal";

export const isBlock = (type?: string | null) => {
  return type === "considered-agreed-approved" ||
    type === "considered-approved" ||
    type === "agreed-approved" ||
    type === "approved";
};

export const MainProgramBlockViewer = () => {
  const mainProgramBlock = useAppSelector(selectMainProgramBlock);
  const mainProgram = useAppSelector(selectMainProgram);
  const dispatch = useAppDispatch();
  const [htmlContent, setHtmlContent] = useState("");
  const {state, methods} = usePayloadedModal<{ widget: Element | null }>();
  const {state: blockModalState, methods: blockModalMethods} = usePayloadedModal<{ widget: Element | null }>();

  useEffect(() => {
    if (mainProgramBlock) {
      setHtmlContent(mainProgramBlock.content);
    }
  }, [mainProgramBlock]);

  if (!mainProgramBlock) {
	  return null;
  }

  const saveBlock = () => {
    dispatch(updateMainProgramBlockAction({
      ...mainProgramBlock,
      mainProgram: {
        id: mainProgram!.id,
      },
      content: htmlContent,
    }));
  };

  const onClick = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as Element;

    if (target.getAttribute("data-type") === "block-control") {
      blockModalMethods.show({widget: (target as any).offsetParent});
      return;
    }

    if (target.getAttribute("data-widget") && !isBlock(target.getAttribute("data-type")!)) {
      methods.show({widget: target});
    }
  };

  return (
    <AppFlex>
      <SaveButton click={saveBlock}/>
      {
        mainProgramBlock.type === PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE ? (
          <BlockTableAdditionalEducationEditor mainProgramBlock={mainProgramBlock}/>
          ) : (
            <DocumentEditor id={mainProgramBlock.id!}
                            setData={setHtmlContent}
                            onClick={onClick}
                            editable
                            content={mainProgramBlock.content}/>
          )
      }
      <WidgetValueModal visible={state.visible}
                        selectedWidget={state.payload?.widget}
                        hide={methods.hide}
                        setHtmlContent={setHtmlContent}/>
      <WidgetBlockSettingsModal visible={blockModalState.visible}
                                selectedWidget={blockModalState.payload?.widget}
                                hide={blockModalMethods.hide}
                                setHtmlContent={setHtmlContent}/>
    </AppFlex>
  );
};